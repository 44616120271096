import React from "react";

class Footer extends React.Component {
  renderer() {
    return (
      <div >
      <footer className="footer">

          <div className="comtech-rights">
            @ 2019 Comtech Telecommunications Corp. All Rights Reserved.
          </div>


        <div className="float-child-right">
          <span className="link">
            <a
              href="http://www.comtechtel.com/privacy-policies"
              target="_blank"
              rel="noopener noreferrer"
            >
              Privacy Policy
            </a>
          </span>
          <span className="link">
            <a
              href="https://www.comtechenterprise.com/about-us/e-privacy"
              target="_blank"
              rel="noopener noreferrer"
            >
              E-Privacy Policy
            </a>
          </span>
          <span className="link">
            <a
              href="http://www.comtechtel.com/terms-use"
              target="_blank"
              rel="noopener noreferrer"
            >
              Terms of Use
            </a>
          </span>
          <span className="last-link">
            <a
              href="https://www.comtechenterprise.com/about-us/contact"
              target="_blank"
              rel="noopener noreferrer"
            >
              Contact Us
            </a>
          </span>
        </div>
      </footer>
      </div>
    );
  }
  render() {
    return this.renderer();
  }
}
export default Footer;
