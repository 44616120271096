import React from "react";
import { Link } from "react-router-dom";
import RouteData from '../config/routeData.json';
class SubComp extends React.Component {
  renderer() {
    return (
      <>
        <div className="comp">
          <article>
              <div className="description">
            <header>
              <h3>{this.props.name}</h3>
              <p className="font14">{this.props.desc}</p>
            </header>
            </div >
            <div className="upperDiv">
              <div className="subDiv">
                <span className="linkSubComp font14">
                  <Link
                    to={{
                      pathname: this.props.overview,
                      tabName: "REST API",
                      tabValue: "0",
                      overviewFile : this.props.overviewFile
                    }}
                  >
                    Overview
                  </Link>{" "}
                </span>{" "}
                |{" "}
                <span className="linkSubComp font14">
                  {this.props.link ? (
                    <a href={this.props.refLink}>API Reference</a>
                  ) : (
                    <Link
                      to={{
                        pathname: this.props.refLink,
                        tabName: "REST API",
                        tabValue: "0",
                        overviewFile : RouteData.SDK
                      }}
                    >
                      API Reference{" "}
                    </Link>
                  )}
                </span>{" "}
              </div>
            </div>
          </article>
        </div>
      </>
    );
  }
  render() {
    return this.renderer();
  }
}
export default SubComp;
