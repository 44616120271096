import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import * as serviceWorker from "./serviceWorker";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import MainPage from "./component/MainPage";
import Documentation from "./component/Documentation";
import SDKComponent from "./component/SDKComponent";
import RouteData from './config/routeData.json';




ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <Switch>
        <Route exact path="/" component={MainPage}></Route>
        <Route path="/REST" component={Documentation}></Route>
        <Route path="/SDK" component={(props) => <SDKComponent overviewFile={RouteData.SDK} {...props}/>} ></Route>
        <Route path="/ios" component={(props) => <SDKComponent overviewFile={RouteData.ios} {...props}/>} ></Route>
        <Route path="/android" component={(props) => <SDKComponent overviewFile={RouteData.android} {...props}/>} ></Route>
        <Route path="/net" component={(props) => <SDKComponent overviewFile={RouteData.net} {...props}/>} ></Route>
        <Route path="/js" component={(props) => <SDKComponent overviewFile={RouteData.js} {...props}/>} ></Route>
        <Route path="/mapeditor" component={(props) => <SDKComponent overviewFile={RouteData.mapeditor} {...props}/>} ></Route>
        <Route path="/restOverview" component={(props) => <SDKComponent overviewFile={RouteData.restOverview} {...props}/>} ></Route>

      </Switch>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById("root")
);

serviceWorker.unregister();
