import React, { Component } from "react";

class APILink extends Component {
  constructor(props) {
    super(props);
    this.state = {
      index: props.index,
      iconOpenFlag: false,
      name: props.apiLinkData.name,
      apiLinks: props.apiLinkData.subApis ? props.apiLinkData.subApis : [],
      allApiData: props.allApiData,
      selectedId: props.selectedId,
    };
    this.handleClick = this.handleClick.bind(this);
    this.subHandleClick = this.subHandleClick.bind(this);
  }

  handleClick(name) {
    if (this.state.apiLinks.length > 0) {
      let newApiData = this.state.allApiData;
      newApiData.forEach((item) => {
        if (item.name === name) {
          item.keyValue = !item.keyValue;
        }
      });
      this.setState({
        allApiData: newApiData,
        iconOpenFlag: !this.state.iconOpenFlag,
      });
    } else {
      this.props.updateDefinitionLink(name);
    }
  }

  subHandleClick(name, subApi, event) {
    this.props.updateSubApis(name, subApi);
    let liElements = document.getElementsByClassName("active");
    if (liElements.length > 0) {
      liElements[0].classList.remove("active");
      event.target.classList.add("active");
    } else {
      event.target.classList.add("active");
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (!nextProps.updateFlag) {
      return {
        name: nextProps.apiLinkData.name,
        apiLinks: nextProps.apiLinkData.subApis,
      };
    } else {
      return {
        name: nextProps.apiLinkData.name,
        apiLinks: nextProps.apiLinkData.subApis,
        allApiData: nextProps.allApiData,
      };
    }
  }

  render() {
    return (
      <div className="api-link">
        <p
          className="boldClass"
          onClick={() => this.handleClick(this.state.name)}
        >
          {this.state.allApiData[this.state.index]["keyValue"] ? (
            <img alt="down arrow" src={require("../img/arrow_down.svg")} />
          ) : (
            <img alt="up arrow" src={require("../img/arrow.svg")} />
          )}
          <span>{this.state.name}</span>
        </p>
        {this.state.allApiData[this.state.index]["keyValue"] ? (
          <>
            {this.state.apiLinks.map((item, index) => {
              return (
                <li
                  className={
                    this.state.selectedId === item.name
                      ? "active linkClass"
                      : "linkClass"
                  }
                  key={index}
                  id={item.name}
                  onClick={(e) =>
                    this.subHandleClick(this.state.name, item.name, e)
                  }
                >
                  {" "}
                  {item.name}
                </li>
              );
            })}
          </>
        ) : null}
      </div>
    );
  }
}

export default APILink;
