import React from "react";
import APILink from "./APILink.js";
import { Link } from "react-router-dom";

const Sidebar = (props) => {
  let apiLinks = [];
  let linkArray = [];

  if (props.definitionList != null) {
    for (let i = 0; i < props.definitionList.length; i++) {
      let linkObject = {};
      linkObject.keyValue = i===parseInt(props.currentIndex) ? true : false;
      linkObject.name = props.definitionList[i].name;
      linkArray.push(linkObject);
      apiLinks.push(
        <APILink
          index={i}
          key={i}
          apiLinkData={props.definitionList[i]}
          updateSubApis = {props.updateSubApis}
          updateDefinitionLink={props.updateDefinitionLink}
          allApiData={linkArray}
          updateFlag={props.updateFlag}
          currentIndex={props.currentIndex}
          selectedId={props.selectedId}
        />
      );
    }
  }



  return (
    <div className="side-bar">
      <div className="side-bar-header"> <Link to="/">
        <img src={require('../img/comtech-logo.svg')} alt='Comtech' width="150px" height="30px"/></Link>
        <div className="docClass">Documentations</div>
      </div>
      <div className="side-bar-body">
          <div className="tabDiv">

          </div>
        {apiLinks}
      </div>
    </div>
  );
};

export default Sidebar;
