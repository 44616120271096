import React from "react";
import { Link } from "react-router-dom";

class SubHeader extends React.Component {
  renderer() {
    return (
      <>
         <div >
          <div className="">
            <nav className="comtech-header navbar navbar-inverse navbar-light bgDiv">
              <div>
                <Link to="/">
                  <img
                    src={require("../img/comtech-logo.svg")}
                    width="150px"
                    height="30px"
                    alt="comtech logo"
                  />
                </Link>
              </div>
            </nav>
          </div>
        </div>
      </>
    );
  }
  render() {
    return this.renderer();
  }
}
export default SubHeader;
