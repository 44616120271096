import React, { Component } from "react";
import "../App.css";
import Config from "../config/mapConfig.json";
import DataConfig from "../config/configData.json";
import SwaggerUI from "swagger-ui";
import Sidebar from "./Sidebar";
import "../../node_modules/swagger-ui/dist/swagger-ui.css";
import axios from "axios";

class Documentation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      organizationConfig: null,
      definitionList: null,
      definitionLink: "",
      updateFlag: true,
      selectedId: "",
      currentID: props.location.tabValue ? props.location.tabValue : "0",
    };
    this.updateDefinitionLink = this.updateDefinitionLink.bind(this);
    this.updateSubApis = this.updateSubApis.bind(this);
  }

  componentDidUpdate() {
    SwaggerUI({
      domNode: document.getElementById("api-data"),
      spec: this.state.definitionLink,
    });
    let el = document.getElementsByClassName("active");
    if (el.length !== 0) {
      for (var i = 0; i < el.length; i++) {
        if (el[i].id === this.state.selectedId) {
          el[i].classList.add("active");
        } else {
          el[i].classList.remove("active");
        }
      }
    }
    if (this.state.selectedId !== "") {
      let el = document.getElementById(this.state.selectedId);
      if (el !== null) {
        el.classList.add("active");
      }
    }
  }

  updateDefinitionLink(newLink) {
    let apiData = [];
    let that = this;
    apiData = Config.config.apis;

    let result = apiData.find((item) => {
      return item.name === newLink;
    }).url;
    axios
      .get(DataConfig.localUrl + result)
      .then((obj) => {
        let values = Object.keys(obj.data).map(function (key) {
          return obj.data[key];
        });
        that.setState({
          definitionLink: values[0],
          updateFlag: true,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  }

  updateSubApis(name, subApi) {
    let that = this;
    let apiList = [];

    apiList = Config.config.apis;

    apiList.forEach((item, index) => {
      if (name === item.name) {
        item.subApis.forEach((api) => {
          if (subApi === api.name) {
            axios
              .get(DataConfig.localUrl + api.url)
              .then((obj) => {
                that.setState({
                  definitionLink: obj.data,
                  updateFlag: false,
                  selectedId: subApi,
                });
              })
              .catch((err) => {
                console.log(err);
              });
          }
        });
      }
    });
  }



  getFileName = () => {
    let fileName = "";
    if (this.props.location.tabValue) {
        fileName =
          Config.config.apis[this.props.location.tabValue].subApis[0].url;
        this.setState({
          selectedId:
            Config.config.apis[this.props.location.tabValue].subApis[0].name,
        });
    } else {
        fileName = Config.config.apis[0].subApis[0].url;
        this.setState({
          selectedId: Config.config.apis[0].subApis[0].name,
        });
    }
    return fileName;
  };

  componentDidMount() {
    if (Config.config.apis.length > 0 ) {
      let initialFileName = this.getFileName();

      axios
        .get(DataConfig.localUrl + initialFileName)
        .then((obj) => {

            this.setState({
              definitionLink: obj.data,
              definitionList: Config.config.apis,
            });

        })
        .catch((err) => {
          console.log(err);
        });
    }
  }

  render() {
    return (
      <div className="App">
        <Sidebar
          organizationConfig={this.state.organizationConfig}
          definitionList={this.state.definitionList}
          updateDefinitionLink={this.updateDefinitionLink}
          updateSubApis={this.updateSubApis}
          updateFlag={this.state.updateFlag}
          currentTab={this.state.currentTab}
          currentIndex={
           this.state.currentID
          }
          selectedId={this.state.selectedId}
        />
        <div id="api-data" />
      </div>
    );
  }
}

export default Documentation;
