import React from "react";
import { Link } from "react-router-dom";
import RouteData from '../config/routeData.json';

class MapEditor extends React.Component {
   render(){
      return (

        <div className="row map border-top margin-map-editor ">
         <div className="column background-img-mapeditor"></div>
        <div className="column">
            <div className="padding-editor-text">
            <p className="fs-30-b-600">Map Editor</p>

            <p style={{marginTop:12+'px'}}>Map Editor supports customizing map with your datasets and style files. </p>
            </div>
            <br></br>
            <br/>
           <Link
           to={{ pathname: './mapeditor',
                 overviewFile : RouteData.mapeditor}}>
                      <div className="button-css padding-editor-text">
                          Learn more
                      </div>
          </Link>
        </div>
      </div>

      );
   }
}
export default MapEditor;