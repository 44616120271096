import React from "react";
import Footer from "./Footer";
import Config from "../config/configData.json";
import SubComp from "./SubComponent";
import SubHeader from "./SubHeader";
import MapEditor from './MapEditor';
import RouteData from '../config/routeData.json';

class MainPage extends React.Component {
  render() {
    return (
      <>
      <div className="header">
        <SubHeader/>
        <div className="map background-banner">

          <span className="doc-txt">Documentation</span>
          <img className="img" src={require("../img/map.svg")} alt="map" />
          <img className="pin" src={require("../img/pin.svg")} alt="pin" />
        </div>

        <div className="float-container">

          <div className="grid-container2">
            <div className="grid-child ">
              <SubComp name="REST API"
                desc={Config.restDesc}
                refLink="/REST"
                overview="/restOverview"
                overviewFile={RouteData.restOverview}
              />
            </div>

            <div className="grid-child ">
              <SubComp
                name=".NET"
                desc={Config.netDesc}
                refLink="/SDK"
                overview="/net"
                overviewFile={RouteData.net}
              />
            </div>
          </div>
        </div>
        <MapEditor></MapEditor>
      </div>
       <Footer></Footer>
       </>
    );
  }
}

export default MainPage;
