import React from "react";
import SubHeader from "./SubHeader";

class SDKComponent extends React.Component {
   render(){
      return (

         <div>
         <SubHeader></SubHeader>
         <iframe className="iframeClass iframeDiv" width="100%" title="sdk" src={this.props.location.overviewFile?this.props.location.overviewFile:this.props.overviewFile}></iframe>
         </div>

      );
   }
}
export default SDKComponent;